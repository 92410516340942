import React, { useRef } from 'react';
import { ApolloError, useMutation } from '@apollo/client';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  UPSERT_USER_TO_ORGANIZATION,
  UpsertUserToOrganizationParams,
  UpsertUserToOrganizationResponse,
} from '../../../../graphql/mutations/upsertUserToOrganization';
import { USERS_BY_ORG_ID_QUERY_NAME, UsersResponseItem } from '../../../../graphql/query/usersByOrganizationId';
import { FormRef } from '../../../../models/form';
import { LoginFormValue } from '../../../../pages/Login/LoginForm/LoginForm';
import { displayGqlErrors } from '../../ErrorList';
import SideDrawer from '../../SideDrawer';
import { snackbar } from '../../Snackbar';
import { LabelBodyB } from '../../Text/Text.styled';
import UpsertUserForm, { UpsertUserFormValue } from './UpsertUserForm';
import { useParams } from 'react-router-dom';
import { LOCATIONS_BY_ORG_ID, useLocationByOrgIdQuery } from '../../../../graphql/query/locationsByOrgId';
import { SelectOption } from '../../FormSelectField/FormSelectField';
import { ToggleBoxOptions } from '../../../utils/enums.utils';
import { USERS_BY_PARTNER_ID_QUERY_NAME } from '../../../../graphql/query/usersByPartnerId';

interface UpsertUserSideDrawerProps {
  isSideDrawerVisible: boolean;
  toggleSideDrawer: (value?: boolean) => void;
  updateObject: UsersResponseItem;
}

const FormContainer = styled.section`
  margin: 16px;
  width: 100%;
`;

const SectionTitleContainer = styled.div`
  margin-bottom: 16px;
  margin-top: 8px;
`;

const UpsertUserSideDrawer: React.FC<UpsertUserSideDrawerProps> = ({
  isSideDrawerVisible,
  toggleSideDrawer,
  updateObject,
}) => {
  const { organizationId = '' } = useParams<{ organizationId: string }>();
  const { partnerId = '' } = useParams<{ partnerId: string }>();
  const formRef = useRef<FormRef<LoginFormValue>>(null);

  const { locations } = useLocationByOrgIdQuery(organizationId);
  const locationOptions = locations.map<SelectOption>((location) => ({
    label: location.name,
    value: location.id,
  }));

  const marketplaceLocationOptions = [];
  const canCreateMoreLicenses = locations.some((location) => location.canCreateMoreLicenses);
  const licenseLimitReached = locations.some((location) => !location.canCreateMoreLicenses);
  if (canCreateMoreLicenses) {
    marketplaceLocationOptions.push({
      label: <span>Can Create Token</span>,
      title: 'Can Create Token',
      options: locations
        .filter((location) => location.canCreateMoreLicenses)
        .map((location) => {
          return { label: <span>{location.name}</span>, value: location.id, disabled: false };
        }),
    });
  }
  if (licenseLimitReached) {
    marketplaceLocationOptions.push({
      label: <span>License Limit Reached - Contact Support to Create More</span>,
      title: 'License Limit Reached',
      options: locations
        .filter((location) => !location.canCreateMoreLicenses)
        .map((location) => {
          return { label: <span>{location.name}</span>, value: location.id, disabled: true };
        }),
    });
  }

  const initialValues: UpsertUserFormValue = {
    id: updateObject?.id || null,
    avatarId: updateObject?.avatar?.id,
    fullName: updateObject?.fullName || '',
    title: updateObject?.title || '',
    phoneNumber: updateObject?.phoneNumber || '',
    email: updateObject?.username || '',
    role: updateObject?.role || '',
    partnerId: updateObject?.partnerId || '',
    locationIds: updateObject?.locations?.map((location) => location.id) || [],
    assignLicenseToLocationId: updateObject?.marketplaceLicense?.locationId,
    ineligibleMarketplaceReportEnabled: Boolean(updateObject?.ineligibleMarketplaceReportEnabled)
      ? 1
      : // @ts-ignore
        0 ?? ToggleBoxOptions.No,
  };

  const [UpsertUserToOrganization] = useMutation<UpsertUserToOrganizationResponse, UpsertUserToOrganizationParams>(
    UPSERT_USER_TO_ORGANIZATION,
    {
      refetchQueries: [USERS_BY_ORG_ID_QUERY_NAME, USERS_BY_PARTNER_ID_QUERY_NAME, LOCATIONS_BY_ORG_ID],
    }
  );

  const handleFormCompleted = async (formData: UpsertUserFormValue) => {
    try {
      const requestData: UpsertUserToOrganizationParams = {
        data: {
          ...formData,
          userId: updateObject?.id ?? '',
          organizationId: organizationId,
          partnerId,
        },
      };
      await UpsertUserToOrganization({
        variables: requestData,
      });
      snackbar.success({
        message: 'User created Successfully',
      });
      toggleSideDrawer();
    } catch (error) {
      Sentry.captureException(error);
      displayGqlErrors(error as ApolloError);
    }
  };
  return (
    <SideDrawer
      heading={!updateObject ? 'New User' : 'Edit User'}
      isDrawerVisible={isSideDrawerVisible}
      toggleDrawerVisible={toggleSideDrawer}
      ctaButtons={[
        {
          label: !updateObject ? 'NEW USER' : 'UPDATE USER',
          onClick: async () => {
            formRef.current?.submit();
          },
          isDisabled: false,
        },
      ]}
    >
      <FormContainer>
        <SectionTitleContainer>
          <LabelBodyB>User details</LabelBodyB>
        </SectionTitleContainer>
        <UpsertUserForm
          ref={formRef}
          onCompleted={handleFormCompleted}
          locationOptions={locationOptions}
          marketplaceLocationOptions={marketplaceLocationOptions}
          initialValues={initialValues}
        />
      </FormContainer>
    </SideDrawer>
  );
};

export default UpsertUserSideDrawer;
